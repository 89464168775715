import Alpine from "alpinejs";
import htmx from "htmx.org";
import collapse from "@alpinejs/collapse";
import focus from "@alpinejs/focus";
import Swiper from "swiper/bundle";
import persist from '@alpinejs/persist';

Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.plugin(persist);
window.Alpine = Alpine;
window.htmx = htmx;

window.Swiper = Swiper;

if (window.htmx) {
	import("htmx-ext-head-support").then(() => {
		console.log("htmx-ext-head-support loaded");
	});
}

if (nonce !== undefined) {
	document.addEventListener("htmx:configRequest", function (event) {
		event.detail.headers["X-WP-Nonce"] = nonce;
	});
}

document.addEventListener("alpine:init", () => {
	Alpine.data("toggleable", () => ({
		active: false,

		toggle() {
			this.active = !this.active;
		},
	}));

	Alpine.data("videoModal", () => ({
		play: false,

		activate() {
			this.play = !this.play;
		},
	}));

	Alpine.data("sideNavigation", () => ({
		navIsOpen: false,

		toggleMenu() {
			this.navIsOpen = !this.navIsOpen;
		},
	}));
});

document.addEventListener("DOMContentLoaded", function () {
	Alpine.start();
});

function onSubmit(token) {
	document.getElementById("inf_form").submit();
}

function onSubmitNew() {
	document.getElementById("floatingCirclesG").style.display = "block";
	document.getElementById("infusionSubmitButton").style.display = "none";
}

// let popup = document.getElementById("free-book-popup");
// if (popup) {
// 	popup.style.display = "block";
// 	popup.style.margin = "0px";
// 	popup.style.padding = "16px";
// }
